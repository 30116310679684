<script setup lang="ts">
</script>

<template>
    <NuxtLoadingIndicator color="#1181c9" :height="1" :duration="1000" />
    <NuxtLayout>
        <NuxtPwaManifest />
        <NuxtPage />
    </NuxtLayout>
</template>
