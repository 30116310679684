export const appBuildAssetsDir = "_nuxt3/"

export const appPageTransition = false

export const appLayoutTransition = false

export const appHead = {"meta":[{"name":"viewport","content":"width=device-width, initial-scale=1"},{"charset":"utf-8"},{"name":"theme-color","content":"#1181c9"},{"name":"google-site-verification","content":"Z2L7qbv4DnEprWAOIFIYzNNQIemJnilLxtP-CCgMlWY"}],"link":[{"rel":"shortcut icon","type":"image/png","href":"/cdn-cgi/image/fit=scale-down,format=webp,quality=75,width=16,height=16/theme/Spilnu/img/sn/favicon/32x32.png"},{"rel":"preconnect","href":"https://googleads.g.doubleclick.net"},{"rel":"preconnect","href":"https://www.google.com"},{"rel":"preconnect","href":"https://www.googletagmanager.com"},{"rel":"preconnect","href":"https://consentcdn.cookiebot.eu"}],"style":[],"script":[],"noscript":[],"htmlAttrs":{"lang":"da"}}

export const appBaseURL = "/"

export const appCdnURL = ""

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const appRootAttrs = {"id":"__nuxt"}

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const appTeleportAttrs = {"id":"teleports"}

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const cookieStore = true

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"componentName":"NuxtLink"}

export const asyncDataDefaults = {"deep":false,"value":null,"errorValue":null}

export const resetAsyncDataToUndefined = true

export const nuxtDefaultErrorValue = null

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false

export const appId = "nuxt-app"

export const outdatedBuildInterval = 3600000