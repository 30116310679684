export default defineNuxtRouteMiddleware(async (to) => {
    const safeRedirect = useSafeRedirect()
    const { isLoggedIn } = await useAuth()

    if (!isLoggedIn.value) return

    const returnUrl = to.query.returnUrl?.toString()

    if (returnUrl) {
        if (safeRedirect.isSafe(returnUrl)) {
            // Go to returnUrl
            return navigateTo({
                ...to,
                path: returnUrl,
                query: {
                    ...to.query,
                    returnUrl: undefined,
                },
            }, {
                external: true,
                redirectCode: 302,
            })
        }

        // Continue routing but remove returnUrl param
        return navigateTo({
            ...to,
            query: {
                ...to.query,
                returnUrl: undefined,
            },
        }, {
            external: true,
        })
    }

    if (to.query.auth) {
        if (to.query.auth === 'signup' || to.query.auth === 'login' || to.query.auth === 'reactivate') {
            return navigateTo({ ...to, query: {
                ...to.query,
                auth: undefined,
            } })
        }
    }
})
